import "./App.css";
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import BigNumber from "bignumber.js";

const BSCSCAN_API_URL = "https://api.basescan.org/api";
const API_KEY = process.env.REACT_APP_BSCSCAN_API_KEY; // Replace with your actual API key

const tokenContracts = {
  "0xE642657E4F43e6DcF0bd73Ef24008394574Dee28": "RECORD",
  "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913": "USDC",
  "0x4200000000000000000000000000000000000006": "WETH", // Added WETH
};

function formatBalance(balance, decimals = 18) {
  return new BigNumber(balance)
    .dividedBy(new BigNumber(10).pow(decimals))
    .toFixed();
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function BalancesTable() {
  const [balances, setBalances] = useState([]);
  const [totals, setTotals] = useState({ RECORD: 0, USDC: 0, ETH: 0, WETH: 0 });

  async function getEthBalance(address) {
    const params = new URLSearchParams({
      module: "account",
      action: "balance",
      address,
      apiKey: API_KEY,
    });

    const response = await axios.get(`${BSCSCAN_API_URL}?${params}`);
    return response.data.result;
  }

  async function getTokenBalance(address, contractAddress) {
    const params = new URLSearchParams({
      module: "account",
      action: "tokenbalance",
      contractaddress: contractAddress,
      address,
      tag: "latest",
      apiKey: API_KEY,
    });

    const response = await axios.get(`${BSCSCAN_API_URL}?${params}`);
    return response.data.result;
  }

  const checkBalances = useCallback(
    async () => {
      const addresses = [
        "0xDD163A5725d7F794aB7388703C90417d91073BBF",
        "0x66109362d7E37CB4240f574f80FE9218eebd85c4",
        "0xc8b6D661Bd41a92a89e1BDb5F52586e0d90E110a",
        "0x9e8c910ff7DD29cfA6393f18EbAc567f179185d9",
        "0xe92Ae11DEB74623F13bd16db06785Af50081D927",
        "0x6121f4E617a38d88F1eEfDA006EefaD0fD9aCE6C",
        "0xD3bFA9cD1E4DB82910089AD24e45C6549b93dDE3",
        "0xd4c6F562C4c6cC1838cA6c96d9E6B433Cc8aEFba",
        "0x44bb101759BcDCcb69963BC07dF49F0C574f5B38",
        "0x1D6A024352fC4F90438dae02aA962c3c1989ED0B",
        "0x60A507A4b9bB48A9E3C5752A0088883557e98af5",
        "0xD55E855794861A9d1e5CCFCf25e4B64fbd36d572",
        "0x8Cff3458B5e07D7EeCEd82b3EEA64AD95E040e3d",
        "0xa11f0182caEcE266bec4c8e262E627e711b51BfB",
        "0x6127c62EB4a28feF2D940dC6d2beBb613058Efd1",
        "0xc6F9D6144A36070792Ee69a8ebB546fC8a0bDdCf",
        "0xa704fCf6bb27364Ecf591D33e543664A7ad663e2",
      ];

      let balancesData = [];
      let requestCount = 0;

      for (let address of addresses) {
        let addressBalances = {
          address: address,
          ETH: null,
          tokens: {},
        };

        const ethBalanceWei = await getEthBalance(address);
        addressBalances.ETH = formatBalance(ethBalanceWei, 18);

        for (const [contractAddress, tokenName] of Object.entries(
          tokenContracts
        )) {
          const tokenBalanceWei = await getTokenBalance(
            address,
            contractAddress
          );
          addressBalances.tokens[tokenName] = formatBalance(
            tokenBalanceWei,
            tokenName === "USDC" ? 6 : 18
          );
        }

        balancesData.push(addressBalances);

        requestCount++;

        if (requestCount % 5 === 0) {
          await delay(1000); // Wait for 1 second after every 5 requests
        }
      }

      setBalances(balancesData);

      // Calculate totals
      const totals = balancesData.reduce(
        (acc, balance) => {
          acc.ETH += parseFloat(balance.ETH);
          for (const token in balance.tokens) {
            if (!acc[token]) acc[token] = 0;
            acc[token] += parseFloat(balance.tokens[token]);
          }
          return acc;
        },
        { RECORD: 0, USDC: 0, ETH: 0, WETH: 0 }
      );

      setTotals(totals);
    },
    [
      /* dependencies */
    ]
  );

  useEffect(() => {
    checkBalances().catch(console.error);
  }, [checkBalances]);

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Address</th>
            <th>ETH</th>
            {/* Dynamically create columns for tokens */}
            {balances[0] &&
              Object.keys(balances[0].tokens).map((token) => (
                <th key={token}>{token}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {/* Map over balances to create rows */}
          {balances.map((balance) => (
            <tr key={balance.address}>
              <td>{balance.address}</td>
              <td>{parseFloat(balance.ETH).toFixed(2)}</td>
              {/* Dynamically create cells for tokens */}
              {Object.entries(balance.tokens).map(
                ([token, tokenBalance], index) => (
                  <td key={index}>{parseFloat(tokenBalance).toFixed(2)}</td>
                )
              )}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <strong>Total</strong>
            </td>
            <td>{totals.ETH.toFixed(2)}</td>
            <td>{totals.RECORD.toFixed(2)}</td>
            <td>{totals.USDC.toFixed(2)}</td>
            <td>{totals.WETH.toFixed(2)}</td> {/* Added WETH total */}
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default BalancesTable;